





































import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";

export default defineComponent({
  components: {
    MEditor: () => import("@/components/molecules/editor/m-editor.vue"),
  },
  setup(_, { root }) {
    const model = reactive({
      from: "",
      to: "",
      additionalInformation: "",
      englishAdditionalInformation: "",
    });

    const state = reactive({
      loading: false,
      loaded: false,
      success: false,
      error: false as boolean | number,
      valid: false,
      items: [],
      minDate: new Date().toISOString().split("T")[0],
      configExist: false,
      accommodationId: "",
    });

    const rules = {
      required: [(v: string) => !!v || `${root.$t("layout.misc.required")}`],
    };

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 403:
          return `${root.$tc("layout.errors.noPermission")}`;
        case 409:
          return `${root.$tc(
            "panel.event.modules.accommodationModule.add.error409"
          )}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const fetchEvent = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;
      axiosInstance
        .get(`event/${root.$route.params.id}`)
        .then(({ data: { event } }) => {
          model.from = event.accommodationModule.from.split("T")[0];
          model.to = event.accommodationModule.to.split("T")[0];
          model.additionalInformation =
            event.accommodationModule.additionalInformation;
          model.englishAdditionalInformation =
            event.accommodationModule.englishAdditionalInformation;
          state.accommodationId = event.accommodationModule.id;
          state.configExist = event.accommodationModule.id ? true : false;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchEvent);

    const onSubmit = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        isEnabled: true,
        from: model.from,
        to: model.to,
        additionalInformation: model.additionalInformation || null,
        englishAdditionalInformation:
          model.englishAdditionalInformation || null,
      };

      state.loading = true;

      if (!state.configExist) {
        axiosInstance
          .post(`event/${root.$route.params.id}/accommodation-module`, data, {
            headers: {
              event: root.$route.params.id,
            },
          })
          .then(() => {
            state.success = true;
            state.error = false;

            root.$store.commit("snackbar/PUSH_MESSAGE", {
              id: uuid(),
              color: "primary",
              message: root.$tc(
                "panel.event.modules.accommodationModule.config.success"
              ),
            });
            fetchEvent();
          })
          .catch((error) => {
            state.error = error.response.status;
            root.$store.commit("snackbar/PUSH_MESSAGE", {
              id: uuid(),
              color: "error",
              message: getErrorMessage(state.error as number),
            });
          })
          .finally(() => (state.loading = false));
      } else {
        axiosInstance
          .put(`accommodation-module/${state.accommodationId}`, data, {
            headers: {
              event: root.$route.params.id,
            },
          })
          .then(() => {
            state.success = true;
            state.error = false;

            root.$store.commit("snackbar/PUSH_MESSAGE", {
              id: uuid(),
              color: "primary",
              message: root.$tc(
                "panel.event.modules.accommodationModule.config.editSuccess"
              ),
            });
          })
          .catch((error) => {
            state.error = error.response.status;
            root.$store.commit("snackbar/PUSH_MESSAGE", {
              id: uuid(),
              color: "error",
              message: getErrorMessage(state.error as number),
            });
          })
          .finally(() => (state.loading = false));
      }
    };

    return { model, state, rules, onSubmit };
  },
});
